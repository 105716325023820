<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="6">
          <b-button class="mr-5" :variant="(filterStatus == 'approved' ? '' : 'outline-') + 'success'" @click="
              filterStatus == 'approved'
                ? (filterStatus = '')
                : (filterStatus = 'approved')
            ">
            {{ $t("Approved") }}
          </b-button>
          <b-button class="mr-5" :variant="(filterStatus == 'pending' ? '' : 'outline-') + 'warning'" @click="
              filterStatus == 'pending' ? (filterStatus = '') : (filterStatus = 'pending')
            ">
            {{ $t("Pending") }}
          </b-button>
          <b-button class="mr-5" :variant="(filterStatus == 'rejected' ? '' : 'outline-') + 'danger'" @click="
              filterStatus == 'rejected'
                ? (filterStatus = '')
                : (filterStatus = 'rejected')
            ">
            {{ $t("Rejected") }}
          </b-button>
        </b-col>
        <b-col cols="6"><b-form-input v-model="searchUser" class="d-inline-block mr-1" :placeholder="$t('Search')" />
        </b-col>
      </b-row>
      <b-table small responsive hover class="mt-3" :items="approvalsComputed" :fields="fields" primary-key="id">
        <template #head(_id)="data">
          <span style="display: none">{{ $t("ID") }}</span>
        </template>
        <template #cell(_id)="data">
          <!-- <span>{{ data.item._id }}</span> -->
        </template>
        <template #head(for)="data">
          <span>{{ $t("User") }}</span>
        </template>
        <template #head(status)="data">
          <span>{{ $t("Status") }}</span>
        </template>
        <template #cell(for)="data">
          <b-link v-if="data.item.type == 'profile'">
            <!-- :to="{
              name: 'approval-profile',
              params: { id: data.item.for._id },
            }"
            target="_blank" -->
            <b-button variant="primary" @click="
                viewId = data.item.for._id;
                $refs.profileModal.show();
              ">{{ data.item.for.name }}</b-button>
          </b-link>
          <b-link v-else>
            <!-- :to="{
              name: 'approval-account',
              params: { id: data.item.for._id },
            }"
            target="_blank" -->
            <b-button variant="primary" @click="
                viewId = data.item.for._id;
                $refs.accountModal.show();
                viewDocuments = data.item.documents;
              ">{{ data.item.for.name }}</b-button>
          </b-link>
        </template>
        <template #head(type)="data">
          <span>{{ $t("Type") + " " + $t("And") + " " + $t("Documents") }}</span>
        </template>
        <template #cell(type)="data">
          <span>{{ $t(data.item.type) }}</span>
          <!--<b-button
            v-for="document in data.item.documents"
            :href="document"
            target="_blank"
            variant="primary"
            class="ml-1"
          >
            <FeatherIcon icon="SearchIcon" />
          </b-button> -->
        </template>
        <template #cell(status)="data">
          <b-badge v-if="data.item.status == 'pending'" variant="warning">
            {{ $t(data.item.status) }}
          </b-badge>
          <b-badge v-else :variant="data.item.status == 'approved' ? 'success' : 'danger'">
            {{ $t(data.item.status) }}
          </b-badge>
        </template>
        <template #cell(actions)="data">
          <div v-if="data.item.status == 'pending'">
            <b-button class="btn-icon m-1" variant="success" v-b-modal.approveModal @click="activeId = data.item._id">
              <feather-icon icon="CheckCircleIcon"></feather-icon>
            </b-button>
            <b-button variant="danger" class="btn-icon m-1" v-b-modal.rejectModal @click="activeId = data.item._id">
              <feather-icon icon="XCircleIcon"></feather-icon>
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>
    <b-modal ref="approveModal" id="approveModal" centered hide-footer @hide="hiddenModal">
      <span>{{ $t("Review Comment") }}</span>
      <b-form-textarea v-model="comment"></b-form-textarea>
      <b-button variant="primary" class="mt-1" @click="review(true)">{{ $t("Approve")
        }}</b-button>
    </b-modal>
    <b-modal ref="rejectModal" id="rejectModal" centered hide-footer @hide="hiddenModal">
      <span>{{ $t("Review Comment") }}</span>
      <b-form-textarea v-model="comment"></b-form-textarea>
      <b-button variant="danger" class="mt-1" @click="review(false)">{{ $t("Reject")
        }}</b-button>
    </b-modal>
    <b-modal ref="accountModal" size="xl" @hide="
        viewId = null;
        viewDocuments = [];
      " hide-footer>
      <account-view v-if="viewId" :id="viewId" :documents="viewDocuments" />
    </b-modal>
    <b-modal hide-footer ref="profileModal" size="xl" @hide="viewId = null">
      <profile-view v-if="viewId" :id="viewId" />
    </b-modal>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BTable,
  BButton,
  BLink,
  BModal,
  BCard,
  BBadge,
  BCol,
  BFormInput,
  BFormTextarea,
  BRow,
} from "bootstrap-vue";
import ProfileView from "./Profile";
import AccountView from "./Account";
import axios from "@axios";
const {
  axiosRoutes: { approval: path },
} = require("/G_CONFIG");
export default {
  components: {
    BTable,
    BRow,
    BFormCheckbox,
    BButton,
    BLink,
    BModal,
    BCard,
    BBadge,
    BCol,
    BFormInput,
    BFormTextarea,
    ProfileView,
    AccountView,
  },

  data() {
    return {
      approvalList: null,
      searchUser: "",
      activeId: null,
      viewId: null,
      comment: "",
      viewDocuments: [],
      filterStatus: "pending",
      fields: [
        { key: "_id", label: "ID", sortable: false, visible: false },
        { key: "for", label: this.$t("User"), sortable: true },
        { key: "type", label: this.$t("Type"), sortable: true },
        { key: "status", label: this.$t("Status"), sortable: true },
        { key: "actions", label: this.$t("Actions"), sortable: false },
      ],
    };
  },
  async created() {
    /* console.log(this.permissionsData); */
    await axios.get(path.getAll).then(({ data }) => {
      this.approvalList = data.map((item) => {
        return {
          ...item,
          documents: item.documents.map((doc) => axios.defaults.baseURL + doc),
        };
      });
    });
    // console.log(this.userList);
  },
  mounted() {
    // this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
    //   console.log("Modal is about to be hidden", bvEvent, modalId);
    // });
  },
  methods: {
    review(status) {
      // console.log(status, this.activeId, this.comment);
      axios
        .patch(path.edit + "/" + this.activeId, {
          status: status ? "approved" : "rejected",
          comment: this.comment,
        })
        .then(({ data }) => {
          this.approvalList = this.approvalList.map((item) => {
            if (item._id == data._id) {
              return {
                ...data,
                documents: data.documents.map((doc) => axios.defaults.baseURL + doc),
              };
            }
            return item;
          });
        });
      this.$refs.approveModal.hide();
      this.$refs.rejectModal.hide();
    },
    hiddenModal() {
      this.activeId = true;
      this.activeType = true;
      this.comment = "";
    },
  },
  computed: {
    approvalsComputed() {
      let tempList = this.approvalList;
      if (this.filterStatus && tempList) {
        tempList = tempList.filter((item) => {
          return item.status == this.filterStatus;
        });
      }
      if (this.searchUser && tempList) {
        tempList = tempList.filter((item) => {
          return item.for.name.toLowerCase().includes(this.searchUser.toLowerCase());
        });
      }
      return tempList;
    },
  },
};
</script>
<style scoped></style>
